@import "../../styles/constants.scss";

.home {
    height: 100vh;
    width: 100vw;

    margin-bottom: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    // overflow-y: auto;

    .selector {
        display: flex;
        border: 2px solid rgb(220, 220, 220);
        border-radius: 10px;
        overflow: hidden;

        .divider {
            border: 1px solid rgb(220, 220, 220);
        }

        .mode {
            width: 100%;
            height: 100%;
            padding: 10px;
            outline: none;

            &.active {
                background-color: $primary;
                color: #fff;
            }
        }
    }

    .topbar {
        width: 100%;
        min-height: 75px;
        padding: 0 30px 0 30px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
            font-size: 24px;
            font-weight: bold;
        }

        .icon:hover {
            cursor: pointer;
        }
    }

    .container {
        width: 80%;

        .result {
            max-height: 150px;
            overflow: auto; //scroll
            text-align: center;

            .title {
                font-size: 18px;
            }

            .subtitle {
                font-size: 18px;
                font-family: "Roboto mono", monospace;

                &:hover {
                    cursor: pointer;
                }
            }

            @media only screen and (max-width: 512px) {
                margin-top: 30px;
            }
        }
    }

    .titleContainer {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;

        .title {
            font-size: 24px;
            font-weight: bold;
        }

        @media only screen and (max-width: 512px) {
            margin-top: 30px;
        }
    }

    .symmetric {
        width: 100%;
        // height: 70%;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-wrap: wrap;

        align-items: center;
        justify-content: space-between;

        .inputKeyContainer {
            display: flex;
            width: 100%;
            margin-bottom: 10px;

            .generateKeyBtn {
                color: #fff;
            }

            .inputKey {
                margin-right: 30px;
            }
        }

        @media only screen and (max-width: 512px) {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    .asymmetric {
        width: 100%;
        // height: 70%;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-wrap: wrap;

        .inputKeyContainer {
            width: 100%;
            display: flex;

            .generateKeyBtn {
                color: #fff;
            }

            .keys {
                width: 100%;
                margin-right: 30px;

                .key {
                    width: 100%;

                    &:last-child {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .mainarea {
        width: 50%;
        // height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 40px;

        // margin: 30px 10px 0 10px;

        // padding: 10px;

        @media only screen and (max-width: 768px) {
            width: 100%;
            padding: 0;
            // max-width: 250px;
        }

        .title {
            font-weight: bold;
            font-size: 18px;
        }

        .text {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 10px;
            width: 90%;
            height: 60px;
            border-radius: 7px;
            padding: 0 10px 0 10px;

            &.hashText {
                width: unset;
            }
        }

        .textarea {
            width: 100%;
            height: 250px;
            // max-width: 500px;
            width: 95%;
            max-height: 250px;

            @media only screen and (max-width: 768px) {
                width: 100%;
                // max-width: 250px;
            }
        }

        .importLabel {
            cursor: pointer;
        }

        .textHashButton {
            color: #fff;
            margin-top: 30px;
        }

        &.asymmetric {
            margin-top: 0;

            .selectKeyContainer {
                margin-top: 30px;
                width: 250px;
            }
        }

        @media only screen and (max-height: 700px) {
            height: 65%;
        }

        @media only screen and (max-width: 512px) {
            width: 100%;
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        .in {
            height: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 0 30px 0 30px;

            .element {
                display: flex;
                align-items: center;
                margin-top: 4px;
                margin-bottom: 4px;
            }
        }

        @media only screen and (max-width: 512px) {
            position: fixed;

            .in {
                justify-content: center;
            }
        }
    }

    @media only screen and (max-width: 512px) {
        height: unset;
        min-height: 100vh;
        padding-bottom: 70px;
    }
}
