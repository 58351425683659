$primary: #365088;
$secondary: #ffffff;
$transparentSecondary: rgba(255, 255, 255, 0.5);
$lightPrimary: #365088;
$transparentPrimary: rgba(56, 167, 184, 0.6);
$lightGrey: #edf5f6;
$transparentLightGrey: rgba(237, 245, 246, 0.65);
$grey: #dcdcdc;
$disabled: #e5e5e5;
$black: #1f2223;
$gold: #ffd700;
$silver: #c0c0c0;
$bronze: #cd7f32;
$red: #cd5656;

$fontFamily: "Barlow";
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;

$defaultMargin: 5%;

$topBarHeight: 60px;

// New UI
$newGrey: #696969;
$newLightGrey: #f6f6f6;
$newBlack: #404040;
$newRed: #ee6055;
$transparentPrimaryRed: rgba(238, 96, 85, 0.6);
$newWhite: #ffffff;
$newOrange: #f37653;
